<template>
  <FocusTrap>
    <!-- Basic layout-->
    <div id="mycard" class="card">
      <div class="card-header header-elements-inline py-sm-2" style="background-color: whitesmoke;  padding: 5px;">
        <h5 class="card-title">Ledger Group Creation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadParents"></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <p/>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Name:</label>
            <div class="col-md-10">
              <input id="txtname" ref="groupname" type="text" class="form-control" placeholder="Enter Ledger Group Name here..." maxlength="100" v-if="group" v-model="group.name" autocomplete="off" >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Print Name:</label>
            <div class="col-md-10">
              <input ref="printname" type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="group" v-model="group.print_name">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Parent:</label>
            <div class="col-md-10">
              <select class="form-control" ref="parent"  v-if="group" v-model="group.parent_id" @change="showRootInfo" >
                <option v-for="group in ledgergroups" v-bind:value="group.id">
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>

          <div id="rootParent" >
            <div class="form-group row">
              <label class="col-md-2 col-form-label">Nature:</label>
              <div class="col-md-10">
                <select ref="nature" class="form-control " v-if="group" v-model="group.nature" >
                    <option value="1">Assets</option>
                    <option value="2">Liablities</option>
                    <option value="3">Income</option>
                  <option value="4">Expenses</option>
                </select>
              </div>

            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label">Affect Gross Profit:</label>
              <div class="col-md-4">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input ref="affectgrossprofit_yes" type="radio" class="form-input-styled" name="affectgrossprofit" value="true" data-fouc v-if="group"  v-model="group.is_profit" >
                    Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input ref="affectgrossprofit_no" type="radio" class="form-input-styled" name="affectgrossprofit" value="false" checked data-fouc v-if="group"  v-model="group.is_profit">
                    No
                  </label>
                </div>
              </div>

              <label class="col-md-2 col-form-label">Behaves Like Sub-ledger:</label>
              <div class="col-md-4">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="behavelikesubledger" value="true"  data-fouc v-if="group"  v-model="group.is_subledger" >
                    Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="behavelikesubledger"  value="false"  checked data-fouc  v-if="group"  v-model="group.is_subledger" >
                    No
                  </label>
                </div>
              </div>

            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label">Net Dr/Cr Balance:</label>
              <div class="col-md-4">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="netdrcrbalance"  value="true" data-fouc v-if="group" v-model="group.is_net_bal" >
                    Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="netdrcrbalance"  value="false" checked data-fouc v-if="group" v-model="group.is_net_bal" >
                    No
                  </label>
                </div>
              </div>

              <label class="col-md-2 col-form-label">Use for Calculation:</label>
              <div class="col-md-4" >
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="usedforcalc" value="true" data-fouc v-if="group" v-model="group.is_calc" >
                    Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-input-styled" name="usedforcalc"  value="false" checked data-fouc v-if="group" v-model="group.is_calc">
                    No
                  </label>
                </div>
              </div>

            </div>
          </div>

      </div>
      <div class="card-footer" style="padding: 3px;">
        <div class="text-right" >
          <button type="button" class="btn btn-primary" @click="save">Save<i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'LedgerGroupForm',
  components: {

  },
  props: {
    mygroup: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"A","type":0,"name":"","alias":"","print_name":"","parent_id":0,"parent_name":"","nature":0,"is_profit":false,"is_subledger":false,"is_net_bal":false,"is_calc":false}')
    }
  },
  beforeMount () {
    this.group = this.mygroup; // save props data to itself's data and deal with tms
  },
  data () {
    return {
      group: JSON.parse('{"id":0,"status":"A","type":0,"name":"","alias":"","print_name":"","parent_id":0,"parent_name":"","nature":0,"is_profit":false,"is_subledger":false,"is_net_bal":false,"is_calc":false}'),
      ledgergroups: [],
    }
  },
  created () {
    const self = this;
    self.loadParents();
  },
  mounted () {
    const self = this;

    $('#txtname').focus();
    $('.form-control-select2').select2();
    $('.form-input-styled').uniform({
      fileButtonClass: 'action btn bg-pink-400'
    });
    $('.form-control-styled').uniform();
    self.showRootInfo();
  },
  methods: {
    showRootInfo(){
      if(this.$data.group.parent_id == 1){
        $('#rootParent').show();
        this.$emit('ledgergroup_window_resized', 1);
      }else{
        $('#rootParent').hide();
        this.$emit('ledgergroup_window_resized', 0);
      }

    },
    loadParents(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroups`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.ledgergroups =  resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    },
    closeThis(){
      this.$emit('ledgergroup_window_closed');
    },
    save () {
      const self = this;

      try {
        $(self.$data.mycard).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.group.nature = parseInt(self.$data.group.nature);
        self.$data.group.parent_id = parseInt(self.$data.group.parent_id);

        if (!_.isBoolean(self.$data.group.is_profit))
          self.$data.group.is_profit = self.$data.group.is_profit == 'true';
        if (!_.isBoolean(self.$data.group.is_subledger))
          self.$data.group.is_subledger = self.$data.group.is_subledger == 'true';
        if (!_.isBoolean(self.$data.group.is_net_bal))
          self.$data.group.is_net_bal = self.$data.group.is_net_bal == 'true';
        if (!_.isBoolean(self.$data.group.is_calc))
          self.$data.group.is_calc = self.$data.group.is_calc == 'true';

        if (self.$data.group.name.trim().length < 1) {
          alert("Invalid Name");
          self.$refs.groupname.focus();
          return;
        } else if (self.$data.group.parent_id < 1) {
          alert("Invalid Parent");
          self.$refs.parent.focus();
          return
        }


        const requestOptions = {
          method: (self.$data.group.id == 0 ? 'POST' : 'PUT'),
          mode: "cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.group)
        };

        // console.log(JSON.stringify(self.$data.group));


        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroup`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: "Success!", text: resp.msg, type: "success", timer: 3000 });
            self.group = JSON.parse('{"id":0,"status":"A","type":0,"name":"","alias":"","print_name":"","parent_id":0,"parent_name":"","nature":0,"is_profit":false,"is_subledger":false,"is_net_bal":false,"is_calc":false}');
            self.$emit('ledgergroup_saved', resp.data);
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: `failed to load data!`, type: "error" });
        });
        $(self.$data.mycard).unblock();
      }catch (e) {
        alert(e);
      }finally {
        $(self.$data.mycard).unblock();
      }
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  div:focus {
    background: #feff00;
  }
</style>
